import { DiscordAttachments, DiscordInvite, DiscordMessage, DiscordMessages } from "@skyra/discord-components-react"
import { motion } from "framer-motion";
import { Navbar } from "../../components/navbar";
import { Card, Container, DarkBackground, Flex, Text, Title, Wrapper } from "../../globals";
import { ImShield } from 'react-icons/im'
const Plans = () => {
    return (
        <>
            <DarkBackground>
                <Navbar theme={true} />
                <Container>
                    <Wrapper>
                        <Card>
                            <Title>Free</Title>
                            <Text>Cfx Unban <br /> Server Unbans</Text>
                        </Card>
                        <Card>
                            <Flex>
                                <Title> <ImShield /></Title>
                            </Flex>
                            <Text>15€ <br />
                                Cfx unban<br />
                                Anti cheat unban<br />
                                Tokens unbans<br />
                            </Text>
                        </Card>
                        <Card>
                            <Title>Reseller</Title>
                            <Text>Min. 2 keys, 6€ per key</Text>
                        </Card>
                    </Wrapper>
                </Container>
                <Flex style={{ marginTop: '5rem' }}>
                    <DiscordMessages style={{ background: "transparent", border: "0px" }}>
                        <DiscordMessage profile="favna">
                            Need more information? Join: discord.gg/rockstarst0ck
                            <DiscordAttachments>
                                <DiscordInvite
                                    name="RockStar St0ck"
                                    icon={"https://media.discordapp.net/attachments/982164851822583819/986621593809719336/Comp_1_4.gif"}
                                    url="https://discord.gg/AyKne6TJMd"
                                    online={359}
                                    members={2774}
                                ></DiscordInvite>
                            </DiscordAttachments>
                        </DiscordMessage>
                    </DiscordMessages>
                </Flex>

            </DarkBackground>
        </>

    )
}
export default Plans;