import styled from "styled-components";
import { Text } from "../../globals";

export const GoDown = styled(Text)`
    color: #d8dee9;
    font-size: 1.2rem;
    cursor: pointer;
    margin-top: 0.1rem;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    display: flex;
    padding: 0rem 2rem;
    border-radius: 7px;
    &:hover {
        background: rgba(255, 255, 255, 0.295);
        transition: 0.2s;
    }
`;