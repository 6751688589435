import { Navbar } from "../../components/navbar"
import { Container, Background, Text, Title, Flex, Wrapper } from "../../globals"
import { AiOutlineArrowLeft } from "react-icons/ai"
import { GoDown } from "./styles"
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const Home = () => {
    return (

        <>
            <Navbar theme={false} />
            <Container>
                <Flex>
                    <Title>Rolex Spoofer</Title>
                </Flex>
                <Text>
                    Removing global bans on FiveM🔥
                </Text>
                <Flex>
                    <Link to="/plans" className="nodec">
                        <GoDown className="white-glassmorphism" ><p>View Plans</p><AiOutlineArrowLeft className="icon" /></GoDown>
                    </Link>
                </Flex>
                <Wrapper>
                    <iframe width="1000" height="450" src="https://www.youtube.com/embed/YEqPeYeOKas" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </Wrapper>
            </Container>
        </>

    )
}

export default Home;