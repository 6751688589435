import { NavContainer, NavLink, NavMenu } from './styles';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ModalStatus } from './modal';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

export const Navbar = ({ theme }: { theme: boolean }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const closeModal = () => setIsOpen(false)
    const openModal = () => setIsOpen(true)
    const location = useLocation()
    return (
        <>
            <NavContainer className='white-glassmorphism'>
                <li>
                    <img src={"https://media.discordapp.net/attachments/991358831013658644/1013567821386633286/unknown.png"} alt="/" className="img" />
                </li>
                <NavMenu>
                    {
                        location.pathname !== "/" ? <>
                            <Link to='/' className='nodec'>
                                <NavLink theme={theme}>
                                    Home
                                </NavLink>
                            </Link>
                        </> : null
                    }
                    <Link to='/plans' className='nodec'>
                        <NavLink theme={theme}>
                            Plans
                        </NavLink>
                    </Link>

                    <NavLink theme={theme} onClick={() => (isOpen ? closeModal() : openModal())}>
                        Status
                    </NavLink>
                </NavMenu>
            </NavContainer>
            <AnimatePresence
                exitBeforeEnter={true}
                onExitComplete={() => null}
                initial={false}
            >
                {isOpen && <ModalStatus onClick={closeModal} />}
            </AnimatePresence>
        </>
    )
}