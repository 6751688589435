import styled from "styled-components";

export const Container = styled.div`
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 50px;

  @media screen and (max-width: 960px) {
    padding: 0 30px;
  }
`;

export const Background = styled.div`
  height: 100vh;
  background-color: #bd99ff;
background-color:#ebff99;
background-image:
radial-gradient(at 41% 2%, hsla(10,73%,60%,1) 0px, transparent 50%),
radial-gradient(at 66% 82%, hsla(339,85%,72%,1) 0px, transparent 50%),
radial-gradient(at 7% 36%, hsla(326,98%,60%,1) 0px, transparent 50%),
radial-gradient(at 53% 19%, hsla(334,86%,71%,1) 0px, transparent 50%),
radial-gradient(at 77% 45%, hsla(354,82%,68%,1) 0px, transparent 50%),
radial-gradient(at 47% 60%, hsla(350,76%,60%,1) 0px, transparent 50%),
radial-gradient(at 88% 61%, hsla(305,98%,68%,1) 0px, transparent 50%);
`;

export const DarkBackground = styled.div`
  height: 100vh;
  background: #121212;
`;

export const Text = styled.p`
  font-size: 1.18rem;
  font-weight: 500;
  margin-top: -1.2rem;
  text-align: center;
  font-family: 'Spline Sans', sans-serif;
  margin-bottom: 2.5rem;
  letter-spacing: 1px;
  color: #d8dee9;
`;

export const Title = styled.h1`
  color: white;
  letter-spacing: 1px;
  text-align: center;
  font-family: 'Kanit', sans-serif;
  font-size: 2.4rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  gap: 2rem;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardBackground = styled.div`
  border-radius: 10px;
background-color:#ebff99;
background-image:
radial-gradient(at 41% 2%, hsla(10,73%,60%,1) 0px, transparent 50%),
radial-gradient(at 66% 82%, hsla(339,85%,72%,1) 0px, transparent 50%),
radial-gradient(at 7% 36%, hsla(326,98%,60%,1) 0px, transparent 50%),
radial-gradient(at 53% 19%, hsla(334,86%,71%,1) 0px, transparent 50%),
radial-gradient(at 77% 45%, hsla(354,82%,68%,1) 0px, transparent 50%),
radial-gradient(at 47% 60%, hsla(350,76%,60%,1) 0px, transparent 50%),
radial-gradient(at 88% 61%, hsla(305,98%,68%,1) 0px, transparent 50%);
`;

export const Card = styled(CardBackground)`
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    cursor: pointer;
    width: 400px;
    height: 210px;
    justify-content: center;
    align-items: center;
    transition: 0.4s;

    @media screen and (max-width: 960px) {
        width: 350px;
    }

    &:hover {
      transition: 0.4s;
      transform: scaleX(1.05) scaleY(1.05);
    }
`;