import { FC, ReactElement } from "react"
import { motion } from "framer-motion"
type Props = {
    children: ReactElement;
    onClick: () => void;
}
const Backdrop: FC<Props> = ({ children, onClick }) => {
    return (
        <motion.div
            className="backdrop"
            onClick={onClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {children}
        </motion.div>
    )
}

export default Backdrop;