import styled from 'styled-components';

export const NavContainer = styled.nav`	
	position: sticky; 
	top: 0;
	opacity: 0.95;
	margin-bottom: 3.5rem;
	height: 60px;
	display: flex;
	justify-content: flex-start;
	padding: 0.5rem calc((100vw - 1000px) / 2);
	z-index: 15;
	align-items: center;
    gap: 0.5rem;
	li {
		list-style: none;
		margin: 0;

		img {
			width: 45px;
			border-radius: 10px;
			margin-right: 20px;
			@media screen and (max-width: 960px){
				margin-right: 0;
			}
		}
	}
	@media screen and (max-width: 960px){
		justify-content: center;
	}
`;


type Props = {
	theme: boolean
}

export const NavLink = styled.p<Props>`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
	color: ${({ theme }) => (theme === true ? '#d8dee9' : '#d8dee9')};
	font-family: 'Jost', sans-serif;
	font-weight: 700;
	font-size: 19px;
	text-decoration: none;
	padding: 10px 1rem;
	border-radius: 5px;
	cursor: pointer;
	height: 100%;
	transition: 0.1s;

	&:hover {
		transition: 0.1s;
		color: #101419;
		padding: 10px 1rem;
		background: rgba(255, 255, 255, 0.14);
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(15.5px);
		-webkit-backdrop-filter: blur(15.5px);
	}

`;

export const NavMenu = styled.div`
	display: flex;
	align-items: center;
    gap: 0.7rem;

	@media screen and (max-width: 960px){
		display: none;
	}

`;

export const NavBtn = styled.nav`
	display: flex;
	align-items: center;
	margin-right: 24px;

	@media screen and (max-width: 960px){
		display: none;
	}
`;

export const NavBtnLink = styled.p`
	border-radius: 4px;
	font-size: 20px;
	padding: 8px 22px;
	background: #09090a;
	color: #d8dee9;
	border: none;
	outline: none;
	cursor: pointer;
	transition: 0.4s;
	text-decoration: none;
	font-family: 'Jost', sans-serif;
	font-weight: 700;

	&:hover {
		transition: 0.4s;
		background: #0c0d0d;
		color: #eff1f5;
	}
`;

export const FlexModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    justify-content: center;
  }
`;

export const FlexModalItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
`;

export const Title = styled.h1`
  color: white;
  letter-spacing: 1.5px;
  text-align: left;
  margin-bottom: 5px;
  font-size: 1.3rem;

  @media (max-width: 960px) {
    text-align: center;
  }
`;

export const WorkingSpan = styled.span`
    color: #27FB6B;
    font-weight: 700;
`;


export const Text = styled.p`
  font-size: 1.18rem;
  font-weight: 500;
  text-align: left;
  font-family: 'Spline Sans', sans-serif;
  letter-spacing: 1px;
  color: #d8dee9;
`;
