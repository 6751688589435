import { FC } from "react"
import { motion } from "framer-motion"
import Backdrop from "./backdrop"
import { AiOutlineClose } from 'react-icons/ai'
import { FlexModal, Title, WorkingSpan, Text, FlexModalItems } from "./styles"

type Props = {
    onClick: () => void;
}

const dropIn = {
    hidden: {
        opacity: 0,
        y: "-100vh",
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25
        }
    },
    exit: {
        y: "-100vh",
        opacity: 0,
    }
}

export const ModalStatus: FC<Props> = ({ onClick }) => {
    return (
        <Backdrop onClick={onClick}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal white-glassmorphism"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <FlexModal>
                    <Title>Spoofer Status</Title>
                    <Title><AiOutlineClose onClick={onClick} className="pointer" /></Title>
                </FlexModal>
                <FlexModalItems>
                    <Text>FiveM <WorkingSpan>Working</WorkingSpan></Text>
                    <Text>Valorant <WorkingSpan>Working</WorkingSpan></Text>
                    <Text>Fortnite <WorkingSpan>Working</WorkingSpan></Text>
                </FlexModalItems>
                <FlexModalItems>
                    <Text>Warzone <WorkingSpan>Working</WorkingSpan></Text>
                    <Text>Valorant Bypasser <WorkingSpan>Working</WorkingSpan></Text>
                </FlexModalItems>
            </motion.div >
        </Backdrop >
    )
}
