import { Route, Routes, useLocation } from "react-router-dom";
import Plans from "./pages/features";
import Home from "./pages/home";

import { AnimatePresence } from 'framer-motion';

function App() {
  const location = useLocation()
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/plans" element={<Plans />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;